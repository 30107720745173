import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:5001/api/v1/'

// axios.defaults.baseURL = 'http://localhost:5000/api/subgraph'
// axios.defaults.baseURL = 'http://ec2-3-94-243-115.compute-1.amazonaws.com:3000/api/v1/';
// axios.defaults.baseURL = 'https://topbestsellers.org:3000/api/v1/';

axios.defaults.baseURL = 'https://headlesssoftware.com/api/v1/'

// axios.defaults.baseURL = 'https://topbestsellersbackend.herokuapp.com/api/v1/'

/////////////////////////////////////////////////////////////// User Api /////////////////////////////////////////////////////////////

export async function login(userData: any) {
  return await axios
    .post('/admins/login', userData)
    .then((res) => {
      return {
        status: 'success',
        result: res.data,
      }
    })
    .catch((err) => {
      return {
        status: 'fail',
        result: err.response.data,
      }
    })
}

export async function register(userData: any) {
  return await axios
    .post('/admins/register', userData)
    .then((res) => {
      return {
        status: 'success',
        result: res.data,
      }
    })
    .catch((err) => {
      return {
        status: 'fail',
        result: err.response.data,
      }
    })
}

export async function get_user_info_by_user_id(user_id: string | null) {
  return await axios
    .post(
      '/admins/get_user_info_by_user_id',
      {
        user_id,
      },
      {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
        },
      }
    )
    .then((res) => {
      // console.log(res)
      return res
    })
}

/////////////////////////////////////////////////////////////// Base Api /////////////////////////////////////////////////////////////

export async function submit_user_info() {
  return await axios
    .post(
      '/headsoft/submit_user_info', 
      {
        user_info: 'user_info_aaa'
      }, 
      {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
        }
      }
    )
    .then((res) => {
      return res
    })
}

export async function read_rb2b_profiles_info_from_aws_s3() {
  return await axios
    .post(
      '/headsoft/read_rb2b_profiles_info_from_aws_s3', 
      {

      }, 
      {
        headers: {
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': '*',
        }
      }
    )
    .then((res) => {
      return {
        status: 'success',
        result: res.data,
      }
    })
    .catch((err) => {
      return {
        status: 'fail',
        result: err.response.data,
      }
    })
}